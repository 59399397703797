// Scripts for the support dashboard page template
'use strict'

/**
 * Imports SCSS for Webpack parsing and sets strict mode
 */
import '../../../styles/src/pages/support-dashboard.scss';

/**
 * Component imports
 */
// Support login modal
import '../components/support/login-modal';
// Support login
import '../components/support/login';
// Support dashboard
import '../components/support/dashboard';
// Support widgets
import '../components/support/widgets';

/**
 * Page-specific scripting
 */
